import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Closure from "../components/Closure";
import { HTMLContent } from "../components/Content";

export const DeliveryPageTemplate = () => {
  return (
    <div>
      <h1 className="is-size-2 is-hidden-tablet full-width-logo page-title-font">
        Delivery Information
      </h1>
      <section className="is-hidden-touch">
        <div
          className="full-width-image-container margin-top-0 is-hidden-touch'"
          style={{
            backgroundImage: `url('/img/page-backgrounds/deliveries.jpg')`,
          }}
        >
          <h1
            className="is-hidden-touch page-title-font highlight"
            style={{
              padding: "4rem",
              color: "black",
            }}
          >
            Delivery Information
          </h1>
        </div>
      </section>
      <section>
        {/* <Closure>
          Please be aware that Lonely Lentil will be closed from Tuesday 28th
          May to Monday 17th June 2024. We’re off on a holiday!
        </Closure> */}
      </section>
      <section>
        <div className="container">
          <div className="columns">
            <div className="column ">
              <div className="section">
                <div className="tile is-ancestor">
                  <div className="tile is-parent is-vertical">
                    <div className="tile has-text-centered is-vertical is-child box ">
                      <p className="title tile-title">Collection</p>
                      <p
                        style={{ marginBottom: "30px" }}
                        className="subtitle tile-title"
                      >
                        Tuesdays to Fridays 10am-3pm <br />
                        <br />
                      </p>

                      <div className="column">
                        <a
                          href="https://www.mrsb.kitchen"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={"/img/mrsblogo.webp"}
                            alt="mrsblogo"
                            style={{ height: "110px", marginBottom: "30px" }}
                          />
                        </a>
                        <div>
                          <a
                            href="https://g.page/MrsBKitchen?share"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            See on map
                          </a>
                        </div>

                        <br />
                      </div>
                    </div>
                    {/* <div className="tile has-text-centered is-vertical is-child box ">
                      <p className="title tile-title">Collection</p>
                      <p className="subtitle tile-title">
                        10.30am-5pm <br />
                      </p>

                      <div className="column">
                        <a
                          href="https://www.collineskitchen.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={"/img/colline_logo.png"}
                            alt="collinelogo"
                            style={{ height: "90px", marginBottom: "30px" }}
                          />
                        </a>
                        <p className="subtitle tile-title">
                          at the <br />
                          Newbury Waterside Youth &amp; Community Centre
                        </p>
                        <div>
                          <a
                            href="https://goo.gl/maps/vPhPPsnhvH9eTLoP6"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            See on map
                          </a>
                        </div>

                        <br />
                      </div>
                    </div> */}
                  </div>

                  <div className="tile is-parent is-vertical">
                    <div className="tile has-text-centered is-vertical is-child box">
                      <p className="title tile-title">Delivery</p>

                      <ul>
                        <li>Central Newbury</li>
                      </ul>
                      <p className="title tile-title">£2.00</p>
                      <ul>
                        <li>Thatcham</li>
                        <li>Wash Common</li>
                        <li>Wash Water</li>
                        <li>Emborne</li>
                      </ul>
                      <p className="title tile-title">£2.25</p>

                      <ul>
                        <li>Woolton Hill</li>
                        <li>Ball Hill</li>
                        <li>Hamstead Marshall</li>
                      </ul>
                      <p className="title tile-title">£2.45</p>

                      <ul>
                        <li>Cold Ash</li>
                        <li>Curridge</li>
                        <li>Hermitage</li>
                        <li>Bucklebury</li>
                        <li>Hampstead Norreys</li>
                        <li>Burghclere</li>
                        <li>Chieveley</li>
                      </ul>
                      <p className="title tile-title">£2.75</p>

                      <br />
                    </div>
                  </div>
                  <div className="tile is-parent is-vertical">
                    <div className="tile has-text-centered is-vertical is-child box">
                      <p className="title tile-title">Delivery</p>
                      <p
                        style={{ marginBottom: "30px" }}
                        className="subtitle tile-title"
                      >
                        Add a Lonely Lentil package to your Thatcham Refillable
                        delivery on a Friday! Enquire for more details.
                      </p>
                      <div className="column">
                        <a
                          href="https://thatchamrefillable.co.uk"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={"/img/thatcham_refillable_logo.png"}
                            alt="thatcham_refillable"
                            style={{ height: "110px", marginBottom: "30px" }}
                          />
                        </a>
                        <br />
                        <div>
                          <a
                            href="https://goo.gl/maps/CnPD5mDTzm78i6YN9"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            See on map
                          </a>
                        </div>

                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

DeliveryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const DeliveryPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <DeliveryPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

DeliveryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DeliveryPage;

// <div className="tile has-text-centered is-child box">
//     <p className="title tile-title">Collection</p>
//     <a href = 'https://www.mrsb.kitchen' target="_blank" rel="noopener noreferrer">
//       <img src= {'/img/priscillaslogo.png'} alt = 'priscillaskitchen' />
//     </a>
//     <div >
//       <a href='https://goo.gl/maps/UQGDBvNvgE1cubFd6' target="_blank" rel="noopener noreferrer">
//         See on map
//       </a>
//       <br />
//       <p className = "subtitle">Fridays & Saturdays 10am-3pm</p>
//
//     </div>
//     <p style = {{marginTop: 'auto'}} className="title">£1.50</p>
// </div>

export const DeliveryPageQuery = graphql`
  query DeliveryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

// <div className="tile has-text-centered is-child box">
//   <p className="title tile-title">Collection</p>
//   <p style = {{marginTop: 'auto'}} className="title">Free</p>
//   <p className = "title tile-title">Chieveley Village Market</p>
//   <div>
//     <a href='https://goo.gl/maps/E2Zj75Cccr9HkM3s6' target="_blank" rel="noopener noreferrer">
//       See on map
//     </a>
//   </div>
//   <br />
//   <p className = "subtitle">Saturdays 9:30am-10:30am</p>
// </div>
// </div>
